.favorites-page {
  > h2 {
    text-align: center;
    font-size: 1.9rem;
    letter-spacing: 1px;

    span {
      font-size: 1rem;
    }
  }
}
